@import "styles/bulma/sass/utilities/initial-variables";
@import "styles/bulma/sass/utilities/functions";

@import 'styles/diff2html.scss';
@import 'styles/notifier.scss';
@import 'styles/modal.scss';

// Variable overrides for Bulma
@import 'styles/bulma/overrides.sass';

// regular style toast
@import 'ngx-toastr/toastr';

@import "styles/bulma/bulma";

.snapshot {
    font-family: $family-monospace;
}

.watermark {
    position:absolute;
    z-index:-100;
    background:transparent;
    display:block;
    min-height:80vmin;
    min-width:80vmin;
    top: 30vh;
    left: 40vw;
    color:#495a6c;
    font-size:12vw;
    transform:rotate(-45deg);
    -webkit-transform:rotate(-45deg);
}

.input.field-body.percent-25 {
    width: 25%;
}

a, a:hover{
  cursor: pointer;
}

* {
  border-radius: 0 !important;
}

// overriding navbar hover weirdness
a.navbar-item:hover {
  background-color: transparent !important;
}

.dropdown-hover {
  cursor: pointer;
}


.dark-select {
  background-color: darken($background, 5%) !important;
  color: $text;
  padding: 3pt 5pt;
}

.helper-text{
  color: darken($text, 20%);
  font-size: 9pt;
}

i.fas {
  padding: 0 3pt;
}

.accordian-row, .columns:last-child.accordian-row, .columns.accordian-row {
  margin-bottom: 4pt;
  margin-top: unset;
  cursor: pointer;
}

.tenant-menu {
  right: 0;
  min-width: 25rem;
  padding-top: $dropdown-content-offset;
  position: absolute;
  top: 100%;
  z-index: $dropdown-content-z;
  &.dropdown-content {
    padding-left: 1em;
    padding-right: 1em;
  }
  .tenant {
    padding-bottom: 1em;
  }
  li.tenant:hover {
    background-color: rgb(45,45,45);
    cursor: pointer;
  }
  h2 {
    font-weight: bold;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
  }
  >h3 {
    font-weight: bold;
    margin-bottom: 1em;
  }
}

.permission-error-toastr {
  cursor: default!important;
  .toast-message {
    .reveal-more {
      font-style: italic;
      text-decoration: underline;
    }
    .error-link {
      color: #28deff;
    }
    .error-link:hover {
      color: #177f94;
    }
  }
}

.collapsible-toastr {
  cursor: default!important;
  background-image: none!important;
  padding: 15px!important;
  display: block;
  max-height: 900px;
  overflow-y: auto!important;
  .toast-message {
    .reveal-more {
      font-style: italic;
      text-decoration: underline;
    }
    .error-link {
      color: #28deff;
    }
    .error-link:hover {
      color: #177f94;
    }
  }
}

.inherits-display {
  display: inherit;
}