/* Full screen modal box */
.modalbox {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: transparent;
    transition: background 250ms ease-in-out;
    z-index: 1040;

    > .modal-content-full {
      display: flex;
      flex-direction: column;
      width: 95vw;
      min-height: 90vh;
      margin: 5vh auto;
      border-radius: 6px;
      background: #333333;
      transform: scale(0, 0);
      transition: transform 250ms ease-in-out;

      > .modal-header-full:not(:empty) {
        padding: 6px;
        border-bottom: 1px solid #505050;
      }

      .modal-body-full {
        flex: 1;
        padding: 6px;
        display: flex;
        flex-direction: column;
      }

      .modal-footer-full {
        padding: 6px;
        text-align: right;
      }
    }

    &.modal-visible {
      background: rgba(0,0,0,0.8);

      > .modal-content-full {
        transform: scale(1, 1);
      }
    }

    &:not(.modal-visible) {
      pointer-events: none;
    }
}
