/* Site Colors */
$black: #e9e9e9
$black-bis: hsl(0,0%,86%)
$black-ter: hsl(0,0%,86%)
$cyan-invert: hsl(204, 86%, 10%)
$grey-darker: hsl(0,0%,86%)
$grey-dark: hsl(0,0%,86%)
$grey: hsl(0,0%,70%)
$white: #333333

$body-background-color: #2d2d2d
$background: #333333
$text: #e9e9e9
$text-strong: #e9e9e9
$link: #28deff
$link-hover: #177f94
$link-focus: #177f94
$label-color: #e9e9e9

$footer-background-color: #2d2d2d

$info-invert: hsl(204, 86%, 10%)

$message-background-color: #333333
$message-body-color: #e9e9e9

$modal-background-background-color: #2d2d2d

$navbar-background-color: #333333
$navbar-item-color: #e9e9e9

$subtitle-color: #e9e9e9

$table-background-color: #333333
$table-row-hover-background-color: #2d2d2d

$tabs-link-active-color: #e9e9e9
$tabs-boxed-link-active-background-color: #2d2d2d

$title-color: #e9e9e9

$tooltip-background-color: #4a4a4a
$tooltip-color: #ffffff
$tooltip-radius: 6px

$border-color:#505050

$dropdown-content-background-color: #333
$dropdown-item-color: $text !important
$dropdown-item-hover-color: $text !important
$dropdown-item-hover-background-color: #222
$dropdown-item-active-background-color: $blue
$dropdown-item-active-color: $text !important
$button-focus-color: $text